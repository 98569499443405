import React from "react";
import { withRouter } from "react-router-dom";
import Button from "../../components/Button/Button";

import LinkButton from "../../components/Button/LinkButton";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import UserInfo from "../../components/UserInfo/UserInfo";
import Config from '../../config';

import { ChefNET } from "../../modules/ChefNET/ChefNET";
import './ProfilePage.css';

class ProfilePage extends React.Component {

    handleLogOutButton = () => {
        let chefnet = ChefNET.getInstance();
        chefnet.logout();
        this.props.history.push('/');
    };

    ReloadApp = () => {
        console.log("Reloading App");
        navigator.serviceWorker.getRegistrations()
            .then( function(registrations) {
                for(let registration of registrations) {
                    registration.unregister();
                }
            });
        window.location.reload();
    }

    isOfficialBuild = () => {
        return !!Config.chefNetBuildNumber;
    };

    getBuildNumber = () => {
        return Config.chefNetBuildNumber;
    };
    getBuildTimestamp = () => {
        return Config.chefNetBuildTimestamp;
    };

    renderBuildInfo = () => {

        if (this.isOfficialBuild())
            return (
                <pre className="BuildInfo">Build #{this.getBuildNumber()}, {this.getBuildTimestamp()}</pre>
            );
        else
            return (
                <pre className="BuildInfo">Development Build</pre>
            );
    };

    render() {
        return (
            <div>
                <div className="ProfilePage PageWithFooter">
                    <Logo includeText={true}/>
                    <div className="InfoBlock">
                        <div className="Info">
                            <UserInfo/>
                        </div>
                        <div className="ButtonGroup">
                            <LinkButton className="Primary" to="/viewDietaryRestrictions">My Dietary
                                Restrictions</LinkButton>
                            <Button onClick={this.handleLogOutButton} className="Secondary">Log Out</Button>
                        </div>

                        <Button className="ReloadApp" onClick={this.ReloadApp}>Reload App</Button>
                        {this.renderBuildInfo()}
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }

}
export default withRouter(ProfilePage);
