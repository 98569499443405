
const genericErrorMessage = "An error occurred";

export function errorMessageProvider(event) {
    return getHumanReadableErrorMessage(event)
        || getEventMessage(event)
        || getEventError(event)
        || genericErrorMessage;
}

function getHumanReadableErrorMessage(event) {
    if (event.hasOwnProperty('getHumanReadableErrorMessage')) {
        const message = event.getHumanReadableErrorMessage();
        if (message)
            return message;
    }
}

function getEventMessage(event) {
    return getStringProperty(event, 'message');
}

function getEventError(event) {
    return getStringProperty(event, 'error');
}

function getStringProperty(event, propertyName) {
    return hasStringProperty(event, propertyName) ? event[propertyName] : null;
}

function hasStringProperty(event, propertyName) {
    return event.hasOwnProperty(propertyName) && typeof event[propertyName] === 'string';
}
