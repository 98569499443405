import { Plugins } from '@capacitor/core';

const { Toast } = Plugins;

// Returning something falsy from the message provider will prevent a toast from being shown.
export const nullMessageProvider = (event) => null;

const defaultOptions = {
    // If true all events will be logged to console, even if the messageProvider returns something falsy.
    alsoLogToConsole: true,
    toast: toast,
};

function toast(message) {
    Toast.show({ text: message }).catch((error) => console.error(error));
}

export class Toaster {
    constructor(observable, messageProvider = nullMessageProvider, options = null) {
        this._observable = observable;
        this._messageProvider = messageProvider;

        if (!options)
            options = defaultOptions;
        this._toast = options.toast;
        this._alsoLogToConsole = options.alsoLogToConsole;
    }

    start = () => {
        if (this.isSubscribed() || !this._observable)
            return;

        this._subscription = this._observable.subscribe(this.handleEvent);
    };

    stop = () => {
        if (!this.isSubscribed())
            return;

        this._subscription.unsubscribe();
        this._subscription = null;
    };

    isSubscribed = () => !!this._subscription;

    handleEvent = event => {
        const message = this._messageProvider(event);
        if (message)
            this._toast(message);
        if (this._alsoLogToConsole)
            console.error(event);
    };
}

