import React from "react";
import { withRouter } from "react-router-dom";
import BackXButton from "../../components/BackX/BackX";
import LinkButton from "../../components/Button/LinkButton";
import Line from "../../components/RestrictionItem/Line.svg"
import RestrictionItem from "../../components/RestrictionItem/RestrictionItem";
import { DietaryRestrictionsBase } from "./DietaryRestrictionsBase";
import './ViewDietaryRestrictions.css';

class ViewDietaryRestrictions extends DietaryRestrictionsBase {

    render() {
        return (
            <div className="ViewDietaryRestrictions">

                <BackXButton/>

                <h1>My Dietary Restrictions Notes</h1>

                <div className="RestrictionItems">
                    {this.renderRestrictions()}
                </div>

                <img className="Line" src={Line} alt=""/>

                <h2>OTHER NOTES</h2>
                <p>{this.state.additionalInformation}</p>

                <LinkButton className="Primary" to="/SetDietaryRestrictions">Update Dietary Restrictions</LinkButton>
            </div>
        )
    }

    renderRestrictions() {
        return this.state.restrictions.map(restriction => <RestrictionItem type={restriction} />);
    }
}
export default withRouter(ViewDietaryRestrictions);
