import { BehaviorSubject, Subject } from 'rxjs';

import { BaseError } from '../BaseError/BaseError';
import { defaultRestrictions, DietaryRestrictions } from '../DietaryRestrictions/DietaryRestrictions';
import { AuthTokenStorageKey, ChefNetTokenWatcher } from '../LocalStorage/ChefNetTokenWatcher';
import { Meal } from '../Meal/Meal';
import { anonymousUser, User } from '../User/User';
import { ApiAdapter } from './ApiAdapter';
import Config from "../../config";

export class LoginError extends BaseError {
    getHumanReadableErrorMessage = () => 'Login Failed';
}
export class MealsError extends BaseError {}
export class RegistrationError extends BaseError {}
export class AttachHouseCodeError extends BaseError {}

export class ChefNET {

    static CancelLatePlateEndpoint = "CancelLatePlateOrder";
    static LoginEndpoint = "GetDNNAuthUserData";
    static MealsEndpoint = "GetMeals-v2";
    static RegisterEndpoint = "RegisterAppUser-v2";
    static RequestLatePlateEndpoint = "SubmitLatePlateOrder";
    static SubmitReviewEndpoint = "SubmitReview";
    static GetDietaryInfoEndpoint = "GetDietaryInfo-v2";
    static SubmitDietaryNoteEndpoint = "SubmitDietaryNote-v2";
    static AttachStudentToHouseEndpoint = "AttachStudentToHouse";

    constructor() {
        this._api = new ApiAdapter();
        this._mealsObservable = new BehaviorSubject([]);
        let token = window.localStorage.getItem(AuthTokenStorageKey);
        this._tokenObservable = new BehaviorSubject(token);
        this._userObservable = new BehaviorSubject(User.fromJWT(token));
        this._dietaryRestrictionsObservable = new BehaviorSubject(defaultRestrictions);
        this._errorObservable = new Subject();
        new ChefNetTokenWatcher(this);
    }

    reset() {
        this._mealsObservable.next([]);
        this._dietaryRestrictionsObservable.next(defaultRestrictions);
    }

    getCurrentUserObservable = () => this._userObservable;
    getDietaryRestrictionsObservable = () => this._dietaryRestrictionsObservable;
    getMealsObservable = () => this._mealsObservable;
    getTokenObservable = () => this._tokenObservable;
    getErrorObservable = () => this._errorObservable;

    login = (username, password) => {
        return this._api.get(ChefNET.LoginEndpoint, {username, password}).then(result => {
            if (!!result.error) {
                this._errorObservable.next(new LoginError(result.error));
            } else if (!!result.token) {
                let token = result.token;
                this._tokenObservable.next(token);

                let user = User.fromJWT(token);
                this._userObservable.next(user);
            }
        });
    };

    logout = () => {
        this._userObservable.next(anonymousUser);
        this._tokenObservable.next(null);
        this.reset();
    };

    getMeals = () => {
        this._api.get(ChefNET.MealsEndpoint, {StudentUserID: this._userObservable.getValue().getId(), BuildNumber: Config.chefNetBuildNumber})
            .then(result => {
                if (result.status === "success") {
                    this._mealsObservable.next(result.Meals.map(m => new Meal(m.MealId, m.name, m.date, m.entree, m.fullmenu, m.latePlateStatus, m.dnnMealId, m.mealRating)));
                } else {
                    this._errorObservable.next(new MealsError(result.message));
                }
            });
    };

    register = (Email, HouseCode, FirstName, LastName, Password) => {
        this._api.get(
            ChefNET.RegisterEndpoint,
            {
                Email,
                HouseCode,
                FirstName,
                LastName,
                Password
            })
            .then(result => {
                if (result.status === "error") {
                    this._errorObservable.next(new RegistrationError(result.message));
                } else if (result.status === "success") {
                    this._tokenObservable.next(result.token);
                    this._userObservable.next(User.fromJWT(result.token));
                }
            });
    };

    cancelLatePlate = (MealID) => {
        return this._updateLatePlate(ChefNET.CancelLatePlateEndpoint, MealID);
    };

    requestLatePlate = (MealID) => {
        return this._updateLatePlate(ChefNET.RequestLatePlateEndpoint, MealID);
    };

    _updateLatePlate = (method, MealID) => {
        let UserID = this._userObservable.getValue().getId();

        return this._api.get(
            method,
            {
                MealID,
                UserID
            }
        )
    };

    submitReview = (MealID, StarRating, Comments) => {
        let UserID = this._userObservable.getValue().getId();

        return this._api.get(
            ChefNET.SubmitReviewEndpoint,
            {
                MealID,
                UserID,
                StarRating,
                Comments
            }
        );
    };

    getDietaryRestrictions = () => {
        let UserID = this._userObservable.getValue().getId();

        return this._api.get(ChefNET.GetDietaryInfoEndpoint, { UserID : UserID, BuildNumber : Config.chefNetBuildNumber })
            .then(result => {
                if (result.status === "success")
                    this._dietaryRestrictionsObservable.next(new DietaryRestrictions(result.Info.RestrictionNotes, result.Info.AdditionalInformation, !!result.Info.ThreatFlag, !!result.Info.ThreatApproved));
                else
                    this._dietaryRestrictionsObservable.next(defaultRestrictions);
            });
    };

    setDietaryRestrictions = (RestrictionNotesOptions, AdditionalInformation, Phone) => {
        let UserID = this._userObservable.getValue().getId();

        if(RestrictionNotesOptions.substring(0,2) === ", ")
        {
            RestrictionNotesOptions = RestrictionNotesOptions.substring(2);
        }

        return this._api.get(
            ChefNET.SubmitDietaryNoteEndpoint,
            {
                UserID,
                RestrictionNotesOptions,
                AdditionalInformation,
                Phone
            })
            .then(result => {
                if (result.status === "error") {
                    this._errorObservable.next(new RegistrationError(result.message));
                }
            });
    };

    attachStudentToHouse = (HouseCode) => {
        let UserID = this._userObservable.getValue().getId();
        return this._api.get(
            ChefNET.AttachStudentToHouseEndpoint,
            {
                UserID,
                HouseCode,
                BuildNumber: Config.chefNetBuildNumber
            })
            .then(result => {
                if (result.status === "error") {
                    this._errorObservable.next(new AttachHouseCodeError(result.message));
                }
            });
    }

    static getInstance() {
        if (ChefNET._chefnet === undefined)
            ChefNET._chefnet = new ChefNET();

        return ChefNET._chefnet;
    }
}

export class MockChefNET extends ChefNET {
    setApi(api) {
        this._api = api;
    }
}

export class MockChefNET_FailToLogin extends ChefNET {
    login = () => {
        this._errorObservable.next(new LoginError());
    }
}
