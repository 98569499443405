import moment from "moment";
import React, { Component } from 'react';
import Button from "../Button/Button";
import './DayFilterComponent.css'

// This refers to .MealsPage__PinnedHeader src/pages/MealsWeekPage/MealsWeekPage.css
const IS_HEADER_PINNED = true;

export default class DayFilterComponent extends Component {

    scrollToDate = (dayDifference) => {

        if(dayDifference != null) {
            let date = moment().add(dayDifference, 'days');
            let scrollDateFormat = date.format("YYYYMMDD");

            const element = document.getElementById(scrollDateFormat);

            if (element)
                scrollToElement(element);
        }

        function scrollToElement(element) {
            return IS_HEADER_PINNED ? scrollWithPinnedHeader(element) : scrollWithoutPinnedHeader(element);
        }

        function scrollWithPinnedHeader(element) {
            const yOffset = -200; // shurg?
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
        function scrollWithoutPinnedHeader(element) {
            element.scrollIntoView({behavior: 'smooth'});
        }

    };

    getDateText = (dayDifference) => {

        if (dayDifference === 0)
            return "Today";

        let date = moment().add(dayDifference, 'days');
        return date.format("MMM DD")
    };

    render() {

        return (
            <div className="DayFilterComponent">
                <Button onClick={() => this.scrollToDate(-2)}>{this.getDateText(-2)}</Button>
                <Button onClick={() => this.scrollToDate(-1)}>{this.getDateText(-1)}</Button>
                <Button onClick={() => this.scrollToDate(0)}>{this.getDateText(0)}</Button>
                <Button onClick={() => this.scrollToDate(1)}>{this.getDateText(1)}</Button>
                <Button onClick={() => this.scrollToDate(2)}>{this.getDateText(2)}</Button>
            </div>
        );
    }
}
