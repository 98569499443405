import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Button from "../Button/Button";
import './BackX.css';
import BackX from './BackX.svg';

class BackXButton extends Component {

    handleXButton = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className="XButton">
                <Button onClick={this.handleXButton}><img src={BackX} alt=""/></Button>
            </div>
        )
    }
}

export default withRouter(BackXButton);
