// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let _singleton = null;

const doNothing = () => {};
const canUseServiceWorker = () => browserSupportsServiceWorker() && environmentSupportsServiceWorker();
const shouldUseServiceWorker = () => process.env.NODE_ENV === 'production';
const browserSupportsServiceWorker = () => 'serviceWorker' in navigator;
const environmentSupportsServiceWorker = () => {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    return publicUrl.origin === window.location.origin;
};

class ServiceWorkerOperator {
    constructor(callbacks = {}) {
        this.callbacks = {
            onUpdate: callbacks.onUpdate || doNothing,
            onSuccess: callbacks.onSuccess || doNothing,
            onAbort: callbacks.onAbort || doNothing,
        };

        this.serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    }

    static register = (callbacks) => {
        if (_singleton) {
            console.warn("Redundant service worker registered");
            return;
        }
        _singleton = new ServiceWorkerOperator(callbacks);
        _singleton.register();
    };

    static unregister = () => {
        if (!browserSupportsServiceWorker())
            return;
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
            _singleton = null;
        });
    };

    shouldRegister = () => canUseServiceWorker() && shouldUseServiceWorker();

    register = () => {
        if (!this.shouldRegister()) {
            return;
        }

        window.addEventListener('load', this.onWindowLoad);
    };

    onWindowLoad = () => {
        if (isLocalhost) {
            this.checkValidServiceWorker();
            navigator.serviceWorker.ready.then(() => {
                console.log(
                    'This web app is being served cache-first by a service ' +
                    'worker. To learn more, visit https://bit.ly/CRA-PWA'
                );
            });
        } else {
            this.registerServiceWorkerWithValidUrl();
        }
    };

    registerServiceWorkerWithValidUrl = () => {
        navigator.serviceWorker
            .register(this.serviceWorkerUrl)
            .then(this.onServiceWorkerRegistered)
            .catch(error => {
                console.error('Error during service worker registration:', error);
            });
    };

    onServiceWorkerRegistered = registration => {
        if( registration.waiting != null) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING'});
        }
        registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;

            newWorker.addEventListener('statechange', () => {
                if(newWorker.state==="installed"){
                    newWorker.postMessage({type: 'SKIP_WAITING'});
                }
                if(newWorker.state==="activated"){
                    window.location.reload();
                }
            });
        });
    };

    checkValidServiceWorker = () => {
        fetch(this.serviceWorkerUrl)
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (
                    response.status === 404 ||
                    (contentType != null && contentType.indexOf('javascript') === -1)
                ) {
                    navigator.serviceWorker.ready.then(registration => {
                        registration.unregister().then(() => {
                            window.location.reload();
                        });
                    });
                } else {
                    this.registerServiceWorkerWithValidUrl(this.serviceWorkerUrl);
                }
            })
            .catch(() => {
                console.log(
                    'No internet connection found. App is running in offline mode.'
                );
            });
    };
}


export function register(config) {
    ServiceWorkerOperator.register(config);
}

export function unregister() {
    ServiceWorkerOperator.unregister();
}
