import BaseComponent from "../../components/BaseComponent/BaseComponent";
import Logo from "../../components/Logo/Logo";
import Button from "../../components/Button/Button";
import React from "react";
import {withRouter} from "react-router-dom";
import {ChefNET} from "../../modules/ChefNET/ChefNET";
import './EndOfSemesterPage.css';


class EndOfSemesterPage extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            housecode: ""
        }
    }

    componentDidMount() {
        let chefnet = ChefNET.getInstance();
        this.userSubscription = chefnet.getCurrentUserObservable().subscribe({
            next: this.onCurrentUserChanged,
            error: this.onApiError,
        });
    }

    componentWillUnmount() {
        this.userSubscription.unsubscribe();
        this.userSubscription = null;
    }

    onCurrentUserChanged = (newCurrentUser) => {
        if (newCurrentUser.isAnonymous()) {
            this.props.history.push("/login");
        }
    };

    handleNewHouseCode = (event) => {
        event.preventDefault();
        if(this.state.housecode === ""){
            const chefnet = ChefNET.getInstance();
            chefnet.getErrorObservable().next(new Error("Must enter a valid housecode"));
            return
        }
        const chefnet = ChefNET.getInstance();
        chefnet.attachStudentToHouse(this.state.housecode).then(() => {
            this.props.history.push('/meals');
        });

    }
    handleLogout(){
        let chefnet = ChefNET.getInstance();
        chefnet.logout();
    }
    handleInputChanged = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    keyPressed = event => {
        if(event.key === "Enter") {
            this.handleNewHouseCode(event);
        }
    };

    render() {
        const chefnet = ChefNET.getInstance();
        let user = chefnet.getCurrentUserObservable();
        return (
            <div className="EndOfSemesterPage fadein">
                <Logo includeText={true}/>
                <form className="LoginUI" onSubmit={this.handleLoginButton}>
                    <div>
                        <div className="EndOfSemester__attention">Oh hey there, {user._value.getFirstName()}</div>
                        <div className="EndofSemester__explanation">your account is not actively connected to a house.</div>
                    </div>

                    <div className="EndOfSemesterPage__Form fadein">
                        <div className="EndOfSemesterPage__HouseCode">
                            <div className="EndOfSemester__CTA">Please enter your new house code</div>
                            <input name="housecode"
                               type="text"
                               onKeyPress={this.keyPressed}
                               onChange={this.handleInputChanged}
                               required="true"
                               placeholder="House Code like A1B2C3"
                               maxLength="6" />
                        </div>
                        <div className="fadein">
                            <Button type="submit" onClick={this.handleNewHouseCode}>Connect to House</Button>
                            <div className="EndOfSemesterPage__Logout fadein">
                                {user._value.getEmail()}
                                <Button type="submit" onClick={this.handleLogout}>Logout</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default withRouter(EndOfSemesterPage);
