import moment from "moment";

const MealTimeFormat = "M/D/YYYY";
const DayComparisonFormat = "YYYYMMDD";
const WeekComparisonFormat = "YYYYWW";

export class Meal {
    constructor(mealId, name, date, entree, fullmenu, latePlateStatus, dnnMealId, mealRating) {
        let _mealId = mealId;
        let _name = name;
        let _date = date;
        let _entree = entree;
        let _fullmenu = fullmenu;
        let _latePlateStatus = latePlateStatus;
        let _dnnMealId = dnnMealId;
        let _mealRating = mealRating;

        this.getEntree = () => _entree;
        this.getFullMenu = () => _fullmenu;
        this.getLatePlateStatus = () => _latePlateStatus;
        this.getDnnMealId = () => _dnnMealId;
        this.getMealRating = () => _mealRating;
        this.getMealId = () => _mealId;
        this.getName = () => _name;
        this.getDate = () => _date;
        this.getMoment = () => moment(_date, MealTimeFormat);
        this.getFilterDate = () => this.getMoment().format(DayComparisonFormat);
        this.isTodayOrFuture = () => this.getFilterDate() >= moment().format(DayComparisonFormat);
        this.isToday = () => this.getFilterDate() === moment().format(DayComparisonFormat);
        this.isFuture = () => this.getFilterDate() > moment().format(DayComparisonFormat);
        this.isWeekSpan = () => this.getFilterDate() < moment().startOf('week').fromNow() && this.getFilterDate < moment().endOf('week').fromNow();
        this.isPastWeekOrFuture = () => this.getFilterDate() <= moment().startOf('week').fromNow();
        this.isWeek = () => this.getMoment().format(WeekComparisonFormat) === moment().format(WeekComparisonFormat);

        this.isBreakfast = () => _name === "Breakfast";
        this.getBreakfastStart = () => 7;
        this.getBreakfastEnd = () => 10;

        this.isLunch = () => _name === "Lunch";
        this.getLatePlateLunchCutoff = () => 11;
        this.getLunchStart = () => 11;
        this.getLunchEnd = () => 14; // 2pm local timezone

        this.isDinner = () => _name === "Dinner";
        this.getLatePlateDinnerCutoff = () => 16; // 4pm local timezone
        this.getDinnerStart = () => 17; // 5pm local timezone
        this.getDinnerEnd = () => 20; // 8pm local timezone
    }

    currentLocalTimeHour = () => {
        return moment().hour();
    };

    isAlreadyServed = () => {
        if (this.isFuture()) {
            return false;
        }
        if (this.isToday()) {
            if (this.isBreakfast()) {
                return this.currentLocalTimeHour() > this.getBreakfastEnd();
            }
            if (this.isLunch()) {
                return this.currentLocalTimeHour() > this.getLunchEnd();
            }
            if (this.isDinner()) {
                return this.currentLocalTimeHour() > this.getDinnerEnd();
            }
        }
        return true;
    };

    canRateMeal = () => {
        if (this.isFuture()) {
            return false;
        }
        if (this.isToday()) {
            if (this.isBreakfast()) {
                return this.currentLocalTimeHour() >= this.getBreakfastStart();
            }
            if (this.isLunch()) {
                return this.currentLocalTimeHour() >= this.getLunchStart();
            }
            if (this.isDinner()) {
                return this.currentLocalTimeHour() >= this.getDinnerStart();
            }
        }
        return true; // past days
    }

    canRequestLatePlate = () => {
        if (this.isBreakfast()) {
            return false;
        }
        if (this.isFuture()) {
            return true;
        }
        if (this.isToday()) {
            if (this.isLunch()) {
                return this.currentLocalTimeHour() < this.getLatePlateLunchCutoff();
            }
            if (this.isDinner()) {
                return this.currentLocalTimeHour() < this.getLatePlateDinnerCutoff();
            }
        }
        return false;
    };

    toString = () => {
        return `${this.getMoment().format("ddd MMM Do, LT")} ${this.getName()}: ${this.getEntree()}`;
    };

    static newFromApi(apiJson) {
        return new Meal(apiJson.MealId, apiJson.name, apiJson.date, apiJson.entree, apiJson.fullmenu, apiJson.latePlateStatus, apiJson.dnnMealId, apiJson.mealRating);
    }
}
