import React from 'react';
import Meal from '../Meal/Meal';
import MealButtons from '../MealButtons/MealButtons';
import {RatingCTA} from "../MealRating/RatingCTA";
import "./MealWithButtons.css"

export default class MealWithButtons extends React.Component {
    render() {
        const meal = this.props.meal;
        return (
            <div className="MealWithButtons" id={meal.getFilterDate()}>
                <Meal meal={meal}/>
                <MealButtons
                    meal={meal}
                    onCancelLatePlate={this.props.onCancelLatePlate}
                    onRequestLatePlate={this.props.onRequestLatePlate}/>
                {this.renderRatingCTA()}
            </div>
        );
    }

    renderRatingCTA = () => {
        const meal = this.props.meal;
        if (meal.canRateMeal())
            return (<RatingCTA meal={meal} onClick={this.onRatingClick}/>);
    };

    onRatingClick = () => {
        if (!!this.props.onRatingClick && !this.props.meal.getMealRating())
            this.props.onRatingClick(this.props.meal.getDnnMealId());
    }
}
