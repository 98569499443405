import React from 'react';
import { withRouter } from "react-router-dom";
import BackXButton from "../../components/BackX/BackX";
import BaseComponent from "../../components/BaseComponent/BaseComponent";
import Meal from "../../components/Meal/Meal";
import MealButtons from "../../components/MealButtons/MealButtons";
import { ChefNET } from "../../modules/ChefNET/ChefNET";
import FullMenuIcon from './FullMenu.svg';
import './MealDetailPage.css';

class MealDetailPage extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {meal: undefined};
    }

    componentDidMount() {
        let chefnet = ChefNET.getInstance();
        chefnet.getMeals();
        this.subscribe(
            chefnet.getMealsObservable(),
            {
                next: this.onMealsChanged
            }
        );
    }

    onMealsChanged = (meals) => {
        this.setState({
            meal: meals.filter(m => m.getDnnMealId() === this.props.match.params.mealId)[0]
        });
    };

    render() {
        return (
            <div className="MealDetailPage">
                <BackXButton />
                {this.renderMeal()}
                {this.renderMenu()}
            </div>
        );
    }

    renderMeal() {
        if (!!this.state.meal) {
            return (<Meal meal={this.state.meal} />);
        }
    }

    renderMenu() {
        if (!!this.state.meal) {
            return (
                <div className="FullMenu">
                    <img src={FullMenuIcon} alt="Full Menu" />
                    <h2>This meal has</h2>
                    <ul dangerouslySetInnerHTML={{__html: this.state.meal.getFullMenu()}}></ul>
                    <MealButtons meal={this.state.meal} onRequestLatePlate={this.onRequestLatePlate} onCancelLatePlate={this.onCancelLatePlate} />
                </div>
            );
        }
    }

    onRequestLatePlate = (mealId) => {
        this.props.history.push(`/latePlate/request/${mealId}`);
    };

    onCancelLatePlate = (mealId) => {
        this.props.history.push(`/latePlate/cancel/${mealId}`);
    };
}

export default withRouter(MealDetailPage);
