import { Component } from 'react';

export default class BaseComponent extends Component {
    constructor(props) {
        super(props);
        this.subscriptions = [];
    }

    subscribe(observable, handlers) {
        this.subscriptions.push(observable.subscribe(handlers));
    }

    componentWillUnmount() {
        console.log(`BaseComponent: Unsubscribing ${this.subscriptions.length} subscriptions`);
        this.subscriptions.map(s => s.unsubscribe);
        this.subscriptions = [];
    }

    handleInputChanged = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
}
