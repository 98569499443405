import React from 'react';
import { withRouter } from "react-router-dom";
import BackXButton from "../../components/BackX/BackX";

import BaseMealsComponent from "../../components/BaseMealsComponent/BaseMealsComponent";
import { MealRating } from "../../components/MealRating/MealRating";
import { ChefNET } from "../../modules/ChefNET/ChefNET";
import "./MealRatingPage.css";

class MealRatingPage extends BaseMealsComponent {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            rating: 0,
            meal: undefined
        }
    }

    onMealsUpdated = meals => {
        this.setState({
            meal: meals.filter(m => m.getDnnMealId() === this.props.match.params.mealId)[0]
        });
    };

    onRatingChanged = rating => {
        this.setState({
            rating: rating
        });
    };

    onSubmitClicked = () => {
        if (!this.state.rating) {
            alert("You must provide a rating from 1 to 5!");
            return;
        }

        const chefNet = ChefNET.getInstance();

        chefNet.submitReview(this.state.meal.getDnnMealId(), this.state.rating, this.state.comment)
            .then(r => {
                this.props.history.goBack();
            });
    };

    render() {
        return (
            <div className="MealRatingPage">
                <BackXButton />
                <h1>
                    How was your food today?<br />
                    Help us do better
                </h1>
                <MealRating meal={this.state.meal} rating={this.state.rating} onRatingChanged={this.onRatingChanged} />
                <textarea className="MealRatingPage__Comment" name="comment" placeholder="Any comments on the food?" onChange={this.handleInputChanged} />
                <button onClick={this.onSubmitClicked}>Submit</button>
            </div>
        )
    }
}

export default withRouter(MealRatingPage);
