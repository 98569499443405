import React from 'react';
import { Link, withRouter } from "react-router-dom";
import BaseComponent from '../../components/BaseComponent/BaseComponent';
import Button from "../../components/Button/Button";
import Logo from '../../components/Logo/Logo';

import { ChefNET } from '../../modules/ChefNET/ChefNET';

import Arrow from "./Arrow.svg";
import './Register.css';

class Register extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            houseCode: '',
        };
    }

    componentDidMount = () => {
        const chefnet = ChefNET.getInstance();

        this.subscribe(
            chefnet.getCurrentUserObservable(),
            {
                next: this.onCurrentUserChanged,
                error: this.onApiError
            }
        );
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
    };

    onCurrentUserChanged = (user) => {
        if (!user.isAnonymous())
            this.props.history.push("/setDietaryRestrictions?new-user");
    };
    onApiError = () => {

    };

    handleRegisterButton = () => {
        const chefnet = ChefNET.getInstance();
        chefnet.register(this.state.email, this.state.houseCode, this.state.firstName, this.state.lastName, this.state.password);
    };

    areRequiredFieldsFilledIn = () => {
        return this.state.firstName !== ''
            && this.state.lastName !== ''
            && this.state.email !== ''
            && this.state.password !== ''
            && this.state.passwordConfirmation !== ''
            && this.state.houseCode !== '';
    };

    isPasswordConfirmationOk = () => this.state.password === this.state.passwordConfirmation;

    canSubmit = () => {
        return this.areRequiredFieldsFilledIn() && this.isPasswordConfirmationOk();
    };

    render() {
        return (
            <div className="RegisterPage">
                <Logo includeText={true}/>
                <div className="Register">
                    <div>
                        <h1>Welcome aboard!</h1>
                        <p>We're excited for you to experience College Chefs!</p>
                    </div>

                    <div className="RegisterInputs">
                        <input name="firstName" value={this.state.firstName} onChange={this.handleInputChanged} placeholder="First Name" />
                        <input name="lastName" value={this.state.lastName} onChange={this.handleInputChanged} placeholder="Last Name" />
                        <input name="email" value={this.state.email} onChange={this.handleInputChanged} placeholder="Email Address" autoCapitalize="none"  />
                        <input name="password" value={this.state.password} onChange={this.handleInputChanged} type="password" placeholder="Password"/>
                        <input name="passwordConfirmation" onChange={this.handleInputChanged} value={this.state.passwordConfirmation} type="password" placeholder="Confirm Password"/>
                        <input name="houseCode" onChange={this.handleInputChanged} value={this.state.houseCode} placeholder="House Code" maxLength="6" />
                    </div>

                    <Button disabled={!this.canSubmit()} onClick={this.handleRegisterButton}>Sign Up</Button>

                    <div className="Arrow">Already have an account? <Link to="/login">Log In</Link> <img src={Arrow} alt=""/></div>
                </div>
            </div>
        )
    }
}

export default withRouter(Register);
