
export class User {

    constructor(id, username, firstName, lastName, email, house, supervisor, chef) {
        let _id = id;
        let _username = username;
        let _firstName = firstName;
        let _lastName = lastName;
        let _email = email;
        let _house = house;
        let _supervisor = supervisor;
        let _chef = chef;

        this.getId = () => _id;
        this.getUsername = () => _username;
        this.getFirstName = () => _firstName;
        this.getLastName = () => _lastName;
        this.getEmail = () => _email;
        this.getHouse = () => _house;
        this.getSupervisor = () => _supervisor;
        this.getChef = () => _chef;
    }

    isAnonymous = () => {
        return this === anonymousUser;
    };

    static fromJWT(token) {
        if (token === null)
            return anonymousUser;

        let tokenData = User.parseToken(token);
        return new User(
            tokenData.user_id,
            tokenData.custom.username,
            tokenData.custom.firstname,
            tokenData.custom.lastname,
            tokenData.custom.email,
            tokenData.custom.house,
            tokenData.custom.supervisor,
            tokenData.custom.chef
        );
    }



    static parseToken(token) {

        function Base64Decode(input) {
            var result;
            var newValue = input.replace("-", "+").replace("_", "/");
            try {
                result = decodeURIComponent(escape(window.atob(newValue)));
            } catch (e) {
                throw new Error("Base64Decode failed");
            }
            return result;
        }

        let encoded = token.split(".")[1];
        let decoded = Base64Decode(encoded);

        return JSON.parse(decoded);
    }

}

class AnonymousUser extends User {
    constructor() {
        super(0, 'anon', 'anon', 'anon', 'anon@example.com', 'none', 'anon', 'anon');
    }
}

export const anonymousUser = new AnonymousUser();
