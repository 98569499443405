import React from "react";
import { withRouter } from "react-router-dom";
import BaseMealsComponent from "../../components/BaseMealsComponent/BaseMealsComponent";
import Footer from "../../components/Footer/Footer";

import Logo from "../../components/Logo/Logo";
import MealWithButtons from '../../components/MealWithButtons/MealWithButtons';
import './MealsDayPage.css';

class MealsDayPage extends BaseMealsComponent {

    onMealsUpdated = meals => {
        const todayMeals = meals.filter(meal => {
            return meal.isToday();
        });
        return this.setState({meals: todayMeals});
    };

    displayMessageIfNoMealsToday = (meals) => {
        if(meals && meals.length === 0) {
            return (
                <div className="NoMealsDay">- no meals found for today -</div>
            );
        }
    }

    render() {
        let mealKey = 0;
        return (
            <div className="MealsDayPage PageWithFooter">
                <Logo includeText={true}/>

                <div className="MealsPage__Date">Today's Meals</div>
                <div className="Meals__Day">
                    {this.state.meals.map(meal => (<MealWithButtons key={mealKey++} meal={meal} onRatingClick={this.onRatingClick} onRequestLatePlate={this.onRequestLatePlate} onCancelLatePlate={this.onCancelLatePlate}/>))}
                    { this.displayMessageIfNoMealsToday(this.state.meals) }
                </div>

                <Footer/>
            </div>
        );
    }
}
export default withRouter(MealsDayPage);
