import React from "react";
import { withRouter } from "react-router-dom";
import ProfileIcon from "../../pages/ProfilePage/ProfileIcon.svg"
import DairyIcon from "./DairyIcon.svg"
import './RestrictionItem.css';


import VeganIcon from "./VeganIcon.svg"

class RestrictionItem extends React.Component {

    determineIcon = (type) => {

        if (type === "Vegan")
            return VeganIcon;
        else if (type === "Dairy Free")
            return DairyIcon;
        else
            return ProfileIcon;
    };

    render() {
        return (
            <div className="RestrictionItem">
                <img src={this.determineIcon(this.props.type)} alt=""/>
                <p>{this.props.type}</p>
            </div>
        )
    }
}
export default withRouter(RestrictionItem);
