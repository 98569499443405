import React from "react";
import { withRouter } from "react-router-dom";
import LinkButton from "../Button/LinkButton";

import BellIcon from "./BellIcon.svg";
import BellIconSelected from "./BellIconSelected.svg";
import ChatIcon from "./ChatIcon.svg";
import ChatIconSelected from "./ChatIconSelected.svg";
import './Footer.css';
import PlateIcon from "./PlateIcon.svg";
import PlateIconSelected from "./PlateIconSelected.svg";
import ProfileIcon from "./ProfileIcon.svg";
import ProfileIconSelected from "./ProfileIconSelected.svg";

class Footer extends React.Component {

    comparePaths(path) {
        return path === this.props.location.pathname;

    }

    render() {
        return (
            <div className="Footer">
                <LinkButton to="/meals"><img className="Icon" src={this.comparePaths("/meals") ? BellIconSelected : BellIcon} alt=""/></LinkButton>
                <LinkButton to="/mealsWeek"><img className="Icon" src={this.comparePaths("/mealsWeek") ? PlateIconSelected : PlateIcon} alt=""/></LinkButton>
                <LinkButton to="/viewLatePlates"><img className="Icon" src={this.comparePaths("/viewLatePlates") ? ChatIconSelected : ChatIcon} alt=""/></LinkButton>
                <LinkButton to="/profile"><img className="Icon" src={this.comparePaths("/profile") ? ProfileIconSelected : ProfileIcon} alt=""/></LinkButton>
            </div>
        )
    }
}
export default withRouter(Footer);
