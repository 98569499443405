import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ChefNET } from "../../modules/ChefNET/ChefNET";
import { Toaster } from '../../modules/ErrorToaster/Toaster';
import LifeThreateningInfo from "../../pages/DietaryRestrictionPages/LifeThreateningInfo";
import SetDietaryRestrictions from "../../pages/DietaryRestrictionPages/SetDietaryRestrictions";
import ViewDietaryRestrictions from "../../pages/DietaryRestrictionPages/ViewDietaryRestrictions";
import FeatureCarousel from "../../pages/FeatureCarouselPage/FeatureCarousel";
import LatePlatePage from "../../pages/LatePlatePage/LatePlatePage";

import LoginPage from '../../pages/LoginPage/LoginPage'
import MealDetailPage from "../../pages/MealDetailPage/MealDetailPage";
import MealRatingPage from "../../pages/MealRatingPage/MealRatingPage";
import MealsDayPage from "../../pages/MealsDayPage/MealsDayPage";
import MealsPage from "../../pages/MealsWeekPage/MealsWeekPage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import EndOfSemesterPage from "../../pages/EndOfSemesterPage/EndOfSemesterPage"

import Register from "../../pages/RegisterPage/Register";
import ViewLatePlatesPage from '../../pages/ViewLatePlatesPage/ViewLatePlatesPage';

import PrivateRoute from "../PrivateRoute/PrivateRoute";
import './App.css';
import { errorMessageProvider } from './errorMessageProvider';

class App extends Component {
    constructor(props) {
        super(props);

        const chefnet = ChefNET.getInstance();
        const user = chefnet.getCurrentUserObservable().getValue();
        const restrictions = chefnet.getDietaryRestrictionsObservable().getValue();

        this.state = {
            isAuthenticated: !user.isAnonymous(),
            isThreatFlagged: restrictions.isThreatFlagged()
        };
    }

    componentDidMount() {
        const chefnet = ChefNET.getInstance();

        this.errorToaster = new Toaster(chefnet.getErrorObservable(), errorMessageProvider);
        this.errorToaster.start();

        this.userSubscription = chefnet.getCurrentUserObservable().subscribe({
            next: this.currentUserChanged,
        });
        this.dietaryRestrictionsSubscription = chefnet.getDietaryRestrictionsObservable().subscribe({
            next: this.dietaryRestrictionsChanged,
        });
    }

    currentUserChanged = (currentUser) => {
        this.setState({isAuthenticated: !currentUser.isAnonymous()});
        if (!currentUser.isAnonymous()) {
            const chefnet = ChefNET.getInstance();
            chefnet.getDietaryRestrictions();
        }
    };

    dietaryRestrictionsChanged = (restrictions) => {
        this.setState({isThreatFlagged: restrictions.isThreatFlagged()});
    };

    componentWillUnmount() {
        this.errorToaster.stop();
        this.userSubscription.unsubscribe();
        this.userSubscription = null;
        this.dietaryRestrictionsSubscription.unsubscribe();
        this.dietaryRestrictionsSubscription = null;
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login">
                        <LoginPage/>
                    </Route>

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/meals"
                        component={MealsDayPage}
                    />
                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/mealsWeek"
                        component={MealsPage}
                    />
                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/viewLatePlates"
                        component={ViewLatePlatesPage}
                    />
                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/profile"
                        component={ProfilePage}
                    />

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/setDietaryRestrictions"
                        component={SetDietaryRestrictions}
                    />

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/lifeThreateningInfo"
                        component={LifeThreateningInfo}
                    />

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/viewDietaryRestrictions"
                        component={ViewDietaryRestrictions}
                    />

                    <Route path="/register">
                        <Register/>
                    </Route>

                    <Route path="/featureCarousel">
                        <FeatureCarousel/>
                    </Route>

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/latePlate/:action/:mealId">
                        <LatePlatePage />
                    </PrivateRoute>

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/meal/:mealId/rate">
                        <MealRatingPage />
                    </PrivateRoute>

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/meal/:mealId">
                        <MealDetailPage />
                    </PrivateRoute>

                    <PrivateRoute
                        unauthenticatedRoute="/"
                        isAuthenticated={this.state.isAuthenticated}
                        path="/endOfSemester/">
                        <EndOfSemesterPage />
                    </PrivateRoute>

                    {/*This has to be last*/}
                    <Route path="/">
                        <FeatureCarousel/>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;
