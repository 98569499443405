import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LinkButton from "../../components/Button/LinkButton";
import { ChefNET } from "../../modules/ChefNET/ChefNET";
import bannerSeparator from './bannerSeparator.png';
import './FeatureCarousel.css';
import { features } from "./features-data";

const carouselTransitionIntervalInMilliseconds = 5000;

class FeatureCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { currentFeatureIndex: 0, currentFeature: features[0] };
        this.intervalId = null;
    }

    componentDidMount = () => {
        const chefnet = ChefNET.getInstance();
        this.userSubscription = chefnet.getCurrentUserObservable().subscribe({
            next: this.currentUserChanged
        });

        this.intervalId = setInterval(this.autoAdvanceSlideshow, carouselTransitionIntervalInMilliseconds)
    };

    currentUserChanged = (currentUser) => {
        if (!currentUser.isAnonymous()) {
            this.props.history.push('/meals');
        }
    };

    componentWillUnmount = () => {
        this.userSubscription.unsubscribe();
        this.userSubscription = null;
        clearInterval(this.intervalId);
    };

    autoAdvanceSlideshow = () => {
        let nextId = this.state.currentFeatureIndex + 1;
        if (nextId >= features.length)
            nextId = 0;
        this.setFeature(nextId);
    };

    setFeature = index => {
        this.setState({
            currentFeatureIndex: index,
            currentFeature: features[index]
        });
    };

    getDotClass = index => {
        return this.state.currentFeatureIndex === index ? 'dot active' : 'dot'
    };

    onDotClick = (event, featureIndex) => {
        this.setFeature(featureIndex);
    };

    render() {
        const currentFeature = this.state.currentFeature;
        return (
            <div className="FeatureCarousel">
                <div className="BannerImage" style={{
                    backgroundColor: 'var(--collegeChefsBlack)',
                    backgroundImage: `url(${bannerSeparator}), url(${currentFeature.bannerImage})`,
                }}>
                </div>
                <div className="bannerSeparator"></div>
                <div className="FeatureIcon">
                    <img src={currentFeature.icon} alt=""/>
                </div>
                <div className="homeContent">
                    <div className="FeatureBody">
                        <header>{currentFeature.title}</header>
                        <p>{currentFeature.description}</p>
                    </div>
                    <div className="FeatureIndicators">
                        {[0, 1, 2].map((i) =>
                            <div key={i} onClick={(event) => this.onDotClick(event, i)} className={this.getDotClass(i)}/>)}
                    </div>
                    <div className="ButtonGroup">
                        <LinkButton className="Primary" to="/login">Login</LinkButton>
                        <LinkButton className="Secondary" to="/register">Sign up</LinkButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FeatureCarousel);
