import React, { Component } from 'react';

import CollegeChefsLogoWithText from './CollegeChefs-logo.png';
import './Logo.css';

export default class Logo extends Component {

    render() {
        return (
            <img className="CollegeChefLogo" src={CollegeChefsLogoWithText} alt="CollegeChefs Logo"/>
        )
    }
}
