import React, { Component } from 'react';
import Exclamation from "./Exclamation.svg";
import './RestrictionOption.css'

export default class RestrictionOption extends Component {

    render() {
        return (
            <div className="RestrictionOption">
                <input type="checkbox" checked={this.props.checked} onChange={this.onCheckChanged} />
                <img className={this.props.threatening ? "Visible" : "Hidden"} src={Exclamation} alt=""/>
                <p className={this.props.threatening ? "ThreateningAllergy" : ""}>{this.props.message}</p>
            </div>
        )
    }

    onCheckChanged = (event) => {
        this.props.onChecked(this.props.message, event.target.checked);
    }
}
