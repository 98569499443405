import { Plugins } from '@capacitor/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { shim as objectAssignShim } from 'object.assign';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

const doNothing = () => {};

objectAssignShim();

ReactDOM.render(<App />, document.getElementById('root'));

const { SplashScreen } = Plugins;
SplashScreen.hide();

defineCustomElements(window).then(doNothing);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
