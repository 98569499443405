import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Button.css'

export default class LinkButton extends Component {
    render() {
        const className = this.props.className || "Primary";
        return (
            <Link className={`${className} LinkButton`} to={this.props.to}>{this.props.children}</Link>
        );
    }
}

