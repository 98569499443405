import React from "react";

export class RatingIcon extends React.Component {
    render() {
        return (
            <div className={`RatingIcon ${this.props.selected ? 'Selected' : ''}`} onClick={this.onClick}>
                <svg width="34" height="32" viewBox="0 0 34 32" fill="none">
                    <path d="M21.2117 12.249C21.0814 12.249 20.9662 12.166 20.9273 12.0431L16.9993 0L13.0728 12.0431C13.0324 12.166 12.9171 12.249 12.7884 12.249L0 12.2253L10.3603 19.6384C10.4651 19.7139 10.51 19.8487 10.4696 19.9702L6.49523 32L16.8226 24.5395C16.875 24.501 16.9364 24.4832 16.9992 24.4832C17.0606 24.4832 17.1235 24.5025 17.1759 24.5395L27.5078 32L23.5335 19.9702C23.4931 19.8472 23.5365 19.7139 23.6427 19.6384L34 12.2253L21.2131 12.249H21.2117Z" fill="#919191"/>
                </svg>
            </div>
        )
    }

    onClick = () => {
        if (!!this.props.onClick) {
            this.props.onClick(this.props.rating);
        }
    }
}
