import BaseComponent from "../../components/BaseComponent/BaseComponent";
import { ChefNET } from "../../modules/ChefNET/ChefNET";

export class DietaryRestrictionsBase extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            restrictions: [],
            additionalInformation: "",
            phone: ""
        }
    }

    componentDidMount() {
        const chefnet = ChefNET.getInstance();
        this.subscribe(chefnet.getDietaryRestrictionsObservable(), {
            next: this.dietaryRestrictionsChanged
        });
        chefnet.getDietaryRestrictions();
    }

    dietaryRestrictionsChanged = (restrictions) => {
        this.setState({
            restrictions: restrictions.getRestrictions().split(", "),
            additionalInformation: restrictions.getAdditionalInformation()
        });
    };
}
