import React from 'react';

import { withRouter } from "react-router-dom";
import BackXButton from "../../components/BackX/BackX"
import Button from "../../components/Button/Button";
import RestrictionOption from "../../components/RestrictionInput/RestrictionOption";
import { ChefNET } from "../../modules/ChefNET/ChefNET";
import { DietaryRestrictionsBase } from "./DietaryRestrictionsBase";
import './SetDietaryRestrictions.css'

class SetDietaryRestrictions extends DietaryRestrictionsBase {
    render() {
        return (
            <div className="SetDietaryRestrictions">
                { this.renderCloseButtonIfNeeded() }
                <div>
                    <h1>My Dietary Restrictions Notes</h1>
                    <p>Want to make special requests? Complete your dietary restriction options first!</p>
                </div>

                <div className="RestrictionOptions">
                    <RestrictionOption message="Vegan" checked={this.state.restrictions.indexOf("Vegan") > -1} onChecked={this.onRestrictionSelectionChanged} />
                    <RestrictionOption message="Gluten Free"checked={this.state.restrictions.indexOf("Gluten Free") > -1} onChecked={this.onRestrictionSelectionChanged} />
                    <RestrictionOption message="Vegetarian" checked={this.state.restrictions.indexOf("Vegetarian") > -1} onChecked={this.onRestrictionSelectionChanged} />
                    <RestrictionOption message="Dairy Free" checked={this.state.restrictions.indexOf("Dairy Free") > -1} onChecked={this.onRestrictionSelectionChanged} />
                    <RestrictionOption message="Life-Threatening Allergies" threatening="true" checked={this.state.restrictions.indexOf("Life-Threatening Allergies") > -1} onChecked={this.onRestrictionSelectionChanged} />
                </div>

                <input name="additionalInformation" placeholder="Other allergies to mention?" onChange={this.handleInputChanged} value={this.state.additionalInformation} />

                {this.renderPhoneForm()}

                <Button className="Primary" onClick={this.saveRestrictions}>Save My Restrictions</Button>
                <Button className="Secondary" onClick={this.saveRestrictions}>I Don't Have Any Allergies</Button>
            </div>
        )
    }

    renderCloseButtonIfNeeded = () => {
        if (this.props.location.search.includes("new-user")){
             return (
                 <div className="closeButtonFiller"></div>
             )
        }
        return (
            <BackXButton/>
        )
    }

    renderPhoneForm = () => {
        if (this.state.restrictions.indexOf("Life-Threatening Allergies") > -1) {
            return (
                <div className="PhoneForm">
                    <p>One of our team members will get in touch to confirm your life-threatening allergy</p>
                    <input name="phone" placeholder="Your Phone Number?" onChange={this.handleInputChanged}/>
                </div>
            );
        }
    };

    onRestrictionSelectionChanged = (restriction, checked) => {
        let newRestrictions = this.state.restrictions.slice(0);
        if (checked) {
            newRestrictions.push(restriction);
        } else {
            newRestrictions = newRestrictions.filter(r => r !== restriction);
        }

        this.setState({restrictions: newRestrictions});
    };

    saveRestrictions = () => {

        this.state.phone = this.state.phone.replace(/\D/g,'');

        if((this.state.restrictions.indexOf("Life-Threatening Allergies") > -1) && (this.state.phone === "" || this.state.phone.length < 10 || this.state.phone.length > 10)) {
            const chefnet = ChefNET.getInstance();
            chefnet.getErrorObservable().next(new Error("Please enter a 10-digit phone number"));
            return;
        }

        if((this.state.restrictions.indexOf("Life-Threatening Allergies") > -1) && (this.state.additionalInformation === "") ) {
            const chefnet = ChefNET.getInstance();
            chefnet.getErrorObservable().next(new Error("Please type in what allergies you have!"));
            return;
        }

        const chefnet = ChefNET.getInstance();
        chefnet.setDietaryRestrictions(this.state.restrictions.join(", "), this.state.additionalInformation, this.state.phone)
            .then(() => {
                chefnet.getDietaryRestrictions();
                this.props.history.push('/');
            });
    };
}

export default withRouter(SetDietaryRestrictions);
