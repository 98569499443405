import React from "react";

import { MealRating } from "./MealRating";
import "./RatingCTA.css";

export class RatingCTA extends React.Component {
    render() {
        return (
            <div className="RatingCTA" onClick={this.props.onClick}>
                <h2>How was your food?</h2>
                <MealRating rating={this.props.meal.getMealRating()} />
                <div className="RatingCTA__Separator" />
            </div>
        );
    }
}
