import React, { Component } from 'react';
import { Redirect, Route } from "react-router-dom";
import { ChefNET } from "../../modules/ChefNET/ChefNET";

export default class PrivateRoute extends Component {

    renderComponentOrCallRender = (DestinationComponent, renderDestinationFunction) => {
        if (renderDestinationFunction)
            return (renderDestinationFunction(this.props));
        else
            return (<DestinationComponent {...this.props} />);
    };

    render() {
        const chefnet = ChefNET.getInstance();
        const restrictions = chefnet.getDietaryRestrictionsObservable().getValue();
        const {component, render, isAuthenticated, isThreatFlagged, unauthenticatedRoute, ...remainingProps} = this.props;
        return (
            <Route
                {...remainingProps}
                render={
                    props => {
                        if (restrictions.isThreatFlagged() && remainingProps.path !== "/lifeThreateningInfo") {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/lifeThreateningInfo",
                                        state: {from: "/login"}
                                    }}
                                />
                            );
                        }

                        if (isAuthenticated) {
                            return this.renderComponentOrCallRender(component, render);
                        } else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: unauthenticatedRoute,
                                        state: {from: props.location}
                                    }}
                                />
                            );
                        }
                    }
                }
            />
        );
    }
}
