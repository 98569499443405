import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import './LifeThreateningInfo.css'

class LifeThreateningInfo extends Component {

    render() {
        return (
            <div className="LifeThreateningInfo">
                <p className="Heading">We'll give you a call shortly to discuss your life-threatening allergy</p>
                <p className="Body">For your safety, you won't be able to use the app to request any meals until we verify your health situation</p>
            </div>
        );
    }
}

export default withRouter(LifeThreateningInfo);
