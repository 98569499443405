import icon1 from "./Bell-Icon.svg";
import icon3 from "./Chat-Bubble-Icon.svg";
import icon2 from "./Plate-Icon.svg";
import food1 from "./slide1-960.jpg";
import food2 from "./slide2-960.jpg";
import food3 from "./slide3-960.jpg";

export const features = [
    {
        title: "Request Boxed Meals",
        description: "Not going to make it home for lunch or dinner? Request a boxed meal to be waiting for you when you return.",
        icon: icon1,
        bannerImage: food1,
    },
    {
        title: "Discover Upcoming Meals",
        description: "Our Chef writes the menus in advance for your Chapter. We want you to see what's cookin'!",
        icon: icon2,
        bannerImage: food2,
    },
    {
        title: "Rate and Leave Comments",
        description: "Your feedback is valuable. Help us tailor the menus to your Chapter's needs.",
        icon: icon3,
        bannerImage: food3,
    },
];
