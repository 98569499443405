

export const AuthTokenStorageKey = 'authToken';

export class ChefNetTokenWatcher {
    constructor(chefnet) {
        chefnet.getTokenObservable().subscribe(token => {
            if (token === null)
                window.localStorage.removeItem(AuthTokenStorageKey);
            else
                window.localStorage.setItem(AuthTokenStorageKey, token);
        });
    }
}

