import { BaseError } from './modules/BaseError/BaseError';

class MissingRequiredConfig extends BaseError {}

const Config = {
    chefNetBaseUrl: process.env["REACT_APP_CHEFNET_BASE_URL"],
    chefNetApiPath: process.env["REACT_APP_CHEFNET_API_PATH"],
    chefNetResetPasswordPath: process.env["REACT_APP_CHEFNET_PASSWORD_RESET_PATH"] || '/password-reset',
    chefNetBuildNumber: process.env["REACT_APP_CHEFNET_BUILD_NUMBER"] || null,
    chefNetBuildTimestamp: process.env["REACT_APP_CHEFNET_BUILD_TIMESTAMP"] || null,
};

function validateMandatoryConfig() {
    if (!Config.chefNetBaseUrl)
        throw new MissingRequiredConfig("REACT_APP_CHEFNET_BASE_URL");
    if (!Config.chefNetApiPath)
        throw new MissingRequiredConfig("REACT_APP_CHEFNET_API_PATH");
}

if (process.env['NODE_ENV'] !== 'test')
    validateMandatoryConfig();

export default Config;
