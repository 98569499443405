import {ChefNET} from "../../modules/ChefNET/ChefNET";
import BaseComponent from "../BaseComponent/BaseComponent";

export default class BaseMealsComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {meals: []};
    }

    componentDidMount() {
        const chefnet = ChefNET.getInstance();

        this.subscribe(
            chefnet.getMealsObservable(),
            {
                next: this.onMealsUpdated
            }
        );
        this.subscribe(
            chefnet.getErrorObservable(),
                {
                    next: this.onMealError
                }
        );

        chefnet.getMeals();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onMealsUpdated = meals => {
        console.log("parent was called, expecting the child to have this function");
    };

    onMealError = err => {
        if(err.message.includes("Inactive UserID")){
            console.log("Inactive UserID, we probably are in semester rollover...")
            this.props.history.push('/endOfSemester');
        }
    }

    onRatingClick = (mealId) => {
        this.props.history.push(`/meal/${mealId}/rate`);
    };

    onRequestLatePlate = (mealId) => {
        this.props.history.push(`/latePlate/request/${mealId}`);
    };

    onCancelLatePlate = (mealId) => {
        this.props.history.push(`/latePlate/cancel/${mealId}`);
    };

}
