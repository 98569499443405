import React from 'react';
import "./MealRating.css";

import { RatingIcon } from "./RatingIcon";

export class MealRating extends React.Component {
    render() {
        return (
            <div className="MealRating">
                {[1,2,3,4,5].map(r => {
                    return (
                        <RatingIcon key={r} rating={r} selected={this.props.rating >= r} onClick={this.ratingClicked} />
                    )
                })}
            </div>
        )
    }

    ratingClicked = rating => {
        if (!!this.props.onRatingChanged)
            this.props.onRatingChanged(rating === this.props.rating ? 0 : rating);
    }
}
