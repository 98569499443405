export class DietaryRestrictions {
    constructor(restrictions, additionalInformation, threatFlag, threatApproved) {
        let _restrictions = restrictions;
        let _additionalInformation = additionalInformation;
        let _threatFlag = threatFlag;
        let _threatApproved = threatApproved;

        this.getRestrictions = () => _restrictions;
        this.getAdditionalInformation = () => _additionalInformation;
        this.getThreatFlag = () => _threatFlag;
        this.getThreatApproved = () => _threatApproved;
    }

    isThreatFlagged = () => {
        return !!this.getThreatFlag() && !this.getThreatApproved();
    }
}

class DefaultRestrictions extends DietaryRestrictions {
    constructor() {
        super("", "", false, false);
    }
}

export const defaultRestrictions = new DefaultRestrictions();
