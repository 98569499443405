import React from "react";
import { withRouter } from "react-router-dom";
import BaseMealsComponent from "../../components/BaseMealsComponent/BaseMealsComponent";
import DayFilterComponent from "../../components/DayFilterComponent/DayFilterComponent";
import Footer from "../../components/Footer/Footer";

import Logo from "../../components/Logo/Logo";
import MealWithButtons from '../../components/MealWithButtons/MealWithButtons';
import './MealsWeekPage.css';


class MealsWeekPage extends BaseMealsComponent {

    onMealsUpdated = meals => {
        return this.setState({meals: meals});
    };

    displayMessageIfNoMealsThisWeek = (meals) => {
        if(meals && meals.length === 0) {
            return (
                <div className="NoMealsWeek">- no meals found this week -</div>
            );
        }
    }

    render() {
        let mealKey = 0;
        return (
            <div className="MealsPage PageWithFooter">
                <div className="MealsPage__PinnedHeader">
                    <Logo includeText={true}/>
                    <div className="MealsPage__Date">This week's meals</div>
                    <DayFilterComponent/>
                </div>

                <div className="Meals">
                    {this.state.meals.map(meal => (<MealWithButtons key={mealKey++} meal={meal} onRequestLatePlate={this.onRequestLatePlate} onCancelLatePlate={this.onCancelLatePlate} onRatingClick={this.onRatingClick} />))}
                    {this.displayMessageIfNoMealsThisWeek(this.state.meals)}
                </div>

                <Footer/>
            </div>
        );
    }
}

export default withRouter(MealsWeekPage);
