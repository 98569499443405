import axios from 'axios';
import Config from "../../config";

export class ApiAdapter {
    constructor() {
        this._baseUrl = Config.chefNetBaseUrl + Config.chefNetApiPath;
    }

    get(method, parameters) {
        return axios.get(this._baseUrl, {params: {method, ...parameters}}).then(result => {
            return result.data;
        });
    }
}
