import React from 'react';
import './MealButtons.css';
import {ChefNET} from "../../modules/ChefNET/ChefNET";

export default class MealButtons extends React.Component {
    render() {
        return (
            <div className="MealButtons">
                {this.renderButtons()}
            </div>
        );
    }

    makeBoxedRequest() {
        if(this.props.meal.canRequestLatePlate()) {
            this.props.onRequestLatePlate(this.props.meal.getDnnMealId())
        }else{
            // Past cutoff
            ChefNET.getInstance().getErrorObservable().next(new Error("Oh no, it looks like its too late to make a Boxed Request"));
        }
    }

    cancelBoxedRequest(){
        if(this.props.meal.canRequestLatePlate()) {
            this.props.onCancelLatePlate(this.props.meal.getDnnMealId())
        }else{
            // Past cutoff
            ChefNET.getInstance().getErrorObservable().next(new Error("Oh no, it looks like its too late to cancel your Boxed Request"));
        }
    }

    renderButtons() {

        if (this.props.meal.isAlreadyServed()) {
            return (
                <div className="Meal__AlreadyServed">Already served</div>
            );
        }

        let latePlateStatus = this.props.meal.getLatePlateStatus();
        if (latePlateStatus === 'pending') {
            return (
                <button className="Cancel" onClick={() => this.cancelBoxedRequest()}>Cancel Boxed Meals</button>
            );
        }

        if (this.props.meal.canRequestLatePlate() && latePlateStatus === 'eligible') {
            return (
                <div>
                    <button className="Primary" onClick={() => this.makeBoxedRequest()}>Request Boxed Meal</button>
                </div>
            );
        }

    }
}
