import React from "react";
import { withRouter } from "react-router-dom";

import BaseComponent from '../../components/BaseComponent/BaseComponent';
import Footer from '../../components/Footer/Footer';
import LatePlate from '../../components/LatePlate/LatePlate';
import Logo from '../../components/Logo/Logo';

import { ChefNET } from '../../modules/ChefNET/ChefNET';
import './ViewLatePlatesPage.css';

class ViewLatePlatesPage extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {latePlates: []};
    }

    componentDidMount() {
        const chefnet = ChefNET.getInstance();

        this.subscribe(
            chefnet.getMealsObservable(),
            {
                next: this.onMealsUpdated,
                error: (err) => console.error("Error loading meals", err)
            }
        );

        chefnet.getMeals();
    }

    onMealsUpdated = meals => {
        const latePlates = meals.filter(meal => {
            // console.log(meal.getLatePlateStatus());
            return meal.getLatePlateStatus() !== 'eligible';
        });
        return this.setState({latePlates: latePlates});
    };

    render() {
        let mealKey = 0;
        return (
            <div>

                <div className="ViewLatePlatesPage">
                    <Logo includeText={true}/>
                    <div className="ViewLatePlatesPage_Subheader">My boxed meals this week</div>

                    <div>
                        {this.state.latePlates.map(meal => (<LatePlate key={mealKey++} meal={meal}/>))}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(ViewLatePlatesPage);
