import React, { Component } from 'react';
import './Button.css'

export default class Button extends Component {
    render() {
        const className = this.props.className || "Primary";
        const buttonType = this.props.type || "button";
        return (
            <button disabled={this.props.disabled} className={`${className}`} type={buttonType} onClick={this.props.onClick}>{this.props.children}</button>
        );
    }
}
