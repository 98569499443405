import React from "react";
import { withRouter } from "react-router-dom";
import BaseComponent from '../../components/BaseComponent/BaseComponent';
import { ChefNET } from "../../modules/ChefNET/ChefNET";
import ProfileIcon from "../../pages/ProfilePage/ProfileIcon.svg";
import './UserInfo.css';

class UserInfo extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {latePlates: []};
    }

    componentDidMount() {
        const chefnet = ChefNET.getInstance();

        this.subscribe(
            chefnet.getMealsObservable(),
            {
                next: this.onMealsUpdated,
                error: (err) => console.error("Error loading meals", err)
            }
        );

        chefnet.getMeals();
    }

    onMealsUpdated = meals => {
        const latePlates = meals.filter(meal => {
            // console.log(meal.getLatePlateStatus());
            return meal.getLatePlateStatus() !== 'eligible';
        });
        return this.setState({latePlates: latePlates});
    };

    render() {

        const chefnet = ChefNET.getInstance();
        let user = chefnet.getCurrentUserObservable();

        return (
            <div>

                <div className="UserInfo">
                    <img src={ProfileIcon} alt=""/>
                    <h1>{user._value.getFirstName() + " " + user._value.getLastName()}</h1>
                    <p>{user._value.getHouse()}</p>
                    <p>{user._value.getEmail()}</p>
                </div>
                <div className="PlateInfo">
                    <h1 className="PlateCount">{this.state.latePlates.length}</h1>
                    <p>Current Boxed Meal Requests</p>
                </div>
            </div>
        )
    }
}
export default withRouter(UserInfo);
